.footer {

    padding: 20px 0;
    text-align: center;
}

.footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;  
    gap: 20px;
}

.footer-text {
    font-size: 14px;
    color: #666;
    margin: auto;
}
