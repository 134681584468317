.projects {
    padding: 20px 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: center;
}

.projects h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: var(--text-color);
}

.project-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.project {
    border: 1px solid var(--text-color);
    padding: 40px 40px;
    border-radius: 10px;
    width: 60%;
    max-width: 900px;
    box-shadow: 0 4px 8px rgba(10, 9, 9, 0.444);
}

.img-project {
    display: flex;
    width: fit-content;
    align-self: last baseline;
    overflow: hidden; /* Oculta el contenido desbordado */
}

.img-project img {
    width: 100%; /* Asegura que la imagen se ajuste al contenedor */
    transition: transform 0.8s ease; /* Aplica la transición solo a la transformación */
}

.img-project:hover img {
    transform: scale(2.7) translateX(-4.3rem);
}


.button-content {
    display: flex;
    justify-content: flex-end;
    margin-left: 0.5rem;
}

.github-button{
    background-color: rgba(255, 255, 255, 0); /* Fondo semi-transparente para que resalten sobre la imagen */
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    transition: all 0.3s ease;
}

.github-button:hover {
    transform: scale(1.3);
}

.black-icon{
    height: 24px;
    filter: var(--icon-black-filter);
}

.project-image {
    background-color: rgba(238, 238, 238, 0.894);
    border-radius: 100%;
    width: 100%;
    height: auto;
    border-radius: 10px;
    display: block;
}

.project-content {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
    margin-top: 0;
}

.project h3 {
    display: flex;
    align-items: start;
    color: var(--text-color);
    text-align: start;
    margin-bottom: 1rem;
    margin-top: 0;
    justify-content: space-between;
}

.project ul {
    position: relative;
  }
  
  .project ul li {
    list-style-type: none;
  }
  
  .project ul li:before {
    font-family: FontAwesome;
    content: "\f120";
    position: absolute;
    left : 1em;
    font-size: 0.7em;
    color: var(--text-color);
    
  }

.project p {
    color: var(--text-color);
    text-align: start;
    max-width: 90%;
    font-size: 14px;
    margin-top: 0;
}

.tools-container {
    margin-top: 0;
    display: flex;
    justify-content:flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0;
    font-size: 13px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    gap: 10px;
}

h5 {
    margin-top: 0;
    text-align: start;
}

.tool-box {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 50px;
    border: 0.1rem solid var(--text-color);
    box-shadow: 0 0.5px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.tools-icon {
    height: auto;
    margin: 0 5px;
    /* Espaciado alrededor de los iconos */
    height: 20px;
    filter: grayscale(1) contrast(0.2) brightness(1.1);
}
.tool-box:hover{
    transform: scale(1.1);
}

.tool-box:hover .tools-icon {
    filter: grayscale(0);
}

/* Media Queries */
@media (max-width: 1100px) {
    .project {
        min-width: 400px;
    }

    .project-content {
        flex-direction: column;
        /* Ordena verticalmente */
        align-items: center;
        /* Centra los elementos */
    }

    .project-image {
        width: 100%;
        margin-right: 20px;
        margin-bottom: 20px;
        /* Añade margen inferior para separación */
    }


    .project-item h3 {
        display: flex;
        align-items: star;
        flex-wrap: wrap;
        gap: 10px;
    }

    .tool-box {
        margin-right: 0.1rem;
    }

    h5 {
        margin-right: 0.1rem;
        text-align: start;
    }


}