.sidebar {
    position: fixed;
    right: -60px;
    width: 60px;
    height: calc(100% - 90px); /* Ajusta esto según la altura de tu navbar */
    transition: all 0.3s ease;
    z-index: 1000;
    display: none;
}

.sidebar.open {
    right: 0;
}

.sidebar .logo {
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.sidebar .logo i {
    font-size: 30px;
}

.sidebar .logo .logo-name {
    font-size: 22px;
    margin-left: 10px;
}

.sidebar .menu {
    list-style: none;
    padding: 20px 0;
}

.sidebar .menu-item {
    padding: 20px 20px;
    position: relative;
    margin: auto;
}

.sidebar .menu-link {
    text-decoration: none;
    color: var(--text-color);
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}

.sidebar .menu-link:hover {
    color: #a500a2;
    transform: scale(1.3);
}

.sidebar .menu-link i {
    font-size: 22px;
    margin-right: 15px;
}

@media (max-width: 600px) {
    .sidebar{
        display: inline;
    }
}