.experience {
    padding: 90px 20px 20px 20px;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease;
}

h6 {
    margin-top: 0;
    text-align: start;
    margin-bottom: 1rem;
}

.experience h2 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: var(--text-color);
}

.experience-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.experience-item {
    border: 1px solid var(--text-color);
    padding: 10px 40px 30px 40px;
    border-radius: 10px;
    width: 60%;
    min-width: 400px;
    max-width: 900px;
    box-shadow: 0 4px 8px rgba(10, 9, 9, 0.444);
    gap: 30px;
}

.experience-item h3 {
    display: flex;
    align-items: center;
    color: var(--text-color);
    text-align: start;
    margin-bottom: 0;
}

h5 {
    font-size: medium;
    text-align: start;
    margin-bottom: 0.7rem;
    margin-top: 1rem;
}

.experience-item p {
    color: var(--text-color);
    text-align: start;
    font: medium;
    font-size: 14px;
}


.experience-content {
    display: flex;
    align-items: center;
}

.experience-image {
    width: 200px; 
    height: auto;
    border-radius: 10px;
    margin-right: 2rem;
    transition: transform 0.3s ease; 
    background-color: whitesmoke;
    margin-top: 2rem;
}

.experience-image:hover {
    transform: scale(1.1); 
}

/* Media Queries */
@media (max-width: 1020px) {
    .experience-item{
width: 40%;
    }

    .experience-content {
        flex-direction: column;
        /* Ordena verticalmente */
        align-items: center;
        /* Centra los elementos */
    }

    .experience-image {
        display: flex;
        width: 50%;
        margin: auto;
        margin-top: 2rem;
    }

    .experience-item p {
       width: 100%;
    }

    .experience-item h3 {
        display: flex;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
    }

    .tool-box {
        font-size: 15px;
    }
    .tools-container, h5 {
        margin-bottom: 1em;
        margin-left: 0;
    }
    .experience-item {
        width: 60%;
        min-width: 400px;
        max-width: 700px;
        box-shadow: 0 4px 8px rgba(10, 9, 9, 0.444);
    }
}