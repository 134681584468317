@import '@fortawesome/fontawesome-free/css/all.min.css';

:root {
  --background-color: whitesmoke;
  --text-color: #280d6c;
  --navbar-background-color: #e5e6fa;
  --navbar-text-color: #280d6c;
  --sidebar-background-color: #444;
  --sidebar-text-color: #fff;
  --button-background-color: #333;
  --button-text-color: #fff;
  --card-background-color: #e8e8e8;
  --tool-box-color: #95b9e0;
  --img-logo-filter: hue-rotate(-5deg) saturate(100%) brightness(0.5) contrast(2);
  --icon-filter: brightness(0);
  --icon-filter-express: ;
  --url-background-img: url("https://www.transparenttextures.com/patterns/transparent-square-tiles.png");
  --filter-digipaw: ;
  --icon-black-filter: brightness(0);
}

[data-theme="dark"] {
  --background-color: #000000;
  --text-color: #e0e0ff;
  --navbar-background-color: #171717;
  --navbar-text-color: #fff;
  --sidebar-background-color: #555;
  --sidebar-text-color: #fff;
  --card-background-color: #3b3b3b;
  --button-background-color: #4d4d4d;
  --button-text-color: #fff;
  --tool-box-color: #9191ae;
  --img-logo-filter:brightness(1.2) ; 
  --icon-filter: ;
  --icon-filter-express: brightness(1);
  --url-background-img: url("https://www.transparenttextures.com/patterns/asfalt-light.png");
  --filter-digipaw: contrast(1.5);
  --icon-black-filter: brightness(1000%);
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  transition: background-color 0.3s ease, color 0.3s ease;
  color: var(--text-color);
  background-image: var(--url-background-img);
}
* {
  box-sizing: border-box;
}
