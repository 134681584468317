.app {
  display: flex;
  padding-top: 60px; /* Ajusta esto según la altura de tu navbar */
}

main {
  margin-right: 0; /* Inicialmente sin margen */
  padding: 20px;
  width: 100%;
  transition: margin-right 0.3s ease;
}

section {
  margin-bottom: 40px;
}

