.presentation {
    padding: 50px 20px 90px;
    transition: background-color 0.3s ease, color 0.3s ease;
    color: var(--text-color);
    text-align: center;
    align-items: center;
}

.memoji {
    opacity: 0;
    transform: translateY(20px); /* Ajusta la posición inicial si es necesario */
    transition: all 0.7s ease-out;
    width: 350px;
}

.memoji.show {
    opacity: 1;
    transform: translateY(0);
}


.presentation h1 {
    font-size: 3em;
    margin-bottom: 10px;
    color: var(--text-color);
    text-wrap: balance;
}

.presentation p {
    font-size: 1.2em;
    color: var(--text-color);
    width: 60%;
    margin: auto;
}

.social-links {
    margin-top: 20px;
}

.social-links a {
    display: inline-block;
    margin: 0 10px;
}

.social-links .social-icon {
    filter: var(--icon-filter);
    width: 40px;
    height: 40px;
    transition: transform 0.2s ease;
    position: relative; /* Asegura un contexto de apilamiento adecuado */
    z-index: 1;
    color: whitesmoke;
}

.social-links .social-icon:hover {
    opacity: 80%;
    transform: scale(1.2);
}

.typing {
    white-space: nowrap;
    overflow: hidden;
}

.cursor {
    display: inline-block;
    margin-left: 2px;
    width: 10px;
    height: 1em;
    background-color: var(--text-color);
    animation: blink 1s steps(1) infinite;
}
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink {
    50% { opacity: 0 }
}

@keyframes blink-caret {
    50% { border-color: transparent; }
}

@media (max-width: 600px) {
    .memoji {
        width: 250px;
    }
}