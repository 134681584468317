.navbar {
    position: fixed;
    width: 100%;
    top: 2%;
    z-index: 1000;
}
.navbar-container {
    margin:auto;
    background-color: var(--navbar-background-color);
    border-radius: 50px;
    padding: 0px 10px;
    max-width: 550px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 4px 4px rgba(1, 1, 1, 0.5);
    border: 0.01px solid var(--navbar-background-color);
}


.navbar-logo {
    display: flex;
    align-items: center;
}

.logo-img {
    height: 45px;
    transition: transform 0.6s ease;
    filter: var(--img-logo-filter);
}

.logo-img:hover {
    transform: rotate(-360deg) scale(1.1);
    filter: hue-rotate(50deg) saturate(400%) contrast(2) brightness(0.5) ;
}

.navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar-links li {
    display: flex;
    margin: 0 15px;
    transition: transform 0.3s ease;
}

.navbar-links a {
    color: var(--text-color);
    text-decoration: none;
    font: medium;
    font-size: 14px;
    transition: color 0.3s ease; 
}

.navbar-links a:hover {
    color: #84008d;
}

.navbar-links li:hover {
    transform: scale(1.2); /* Agranda la imagen al pasar el cursor */
}


.theme-button,
.menu-button {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 27px;
    cursor: pointer;
    margin-left: 15px;
    transition: transform 0.3s ease, color 0.3s ease;
}

.menu-button {
    display: none;
}

.theme-button:hover,
.menu-button:hover {
    color: #84008d;
    transform: scale(1.2); /* Agranda la imagen al pasar el cursor */

}

.theme-button:hover{
    transform: rotate(-120deg) scale(1.2);
}

@media (max-width: 600px) {
    .navbar-container {
        width: 390px;
        justify-content: space-between;
    }
    .navbar-links {
        display: none;
    }
    .menu-button{
        display: flex;
    }
}